/*
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2022-08-05 19:22:44
 * @LastEditTime: 2022-08-09 10:49:14
 * @FilePath: /scrm-h5/src/api/modules/imageAndVideo.js
 */
import { get } from '@/api/index'
const pre = '/dapi/b-access-layer/v1/access'

/**
 * 获取员工列表
 */
export const getDetail = (id) => {
  return get(pre + '/content-management/content/get2C?contentId=' + id)
}

